import { styled } from '@mui/system'

export const Header = styled('header')`
	border-bottom: 1px solid ${({ theme }) => theme.palette.grey.light};
	padding: var(--spacing-2) var(--spacing-3);
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: var(--spacing-2) calc(var(--spacing-1) * 4.5);
	}
`

export const LogoWrapper = styled('div')`
	max-width: 172px;
	margin: auto;
`
